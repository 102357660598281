import React, { useEffect, useState, useMemo, useCallback } from "react";
import styles from "./Products.module.scss";
import ProductsCards from "../../components/ProductsCards/ProductsCard";
import { NavLink, useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import Pagination from "../../components/Pagination/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { getProductsOperation } from "../../store/products/operations";
import { getProductsSelector } from "../../store/products/selectors";
import { getFiltersProductsSelector } from "../../store/filters/selectors";
import { getCategoriesMenuSelector } from "../../store/categories/selectors";
import { filtersProductsOperation } from "../../store/filters/operations";
import ProductsFilter from "../../components/ProductsFilter/ProductsFilter";
import Crumbs from "../../components/Crumbs/Crumbs";

const Products = () => {
    const dispatch = useDispatch();
    const categoriesMenu = useSelector(getCategoriesMenuSelector);
    const filterProducts = useSelector(getFiltersProductsSelector);
    const getProducts = useSelector(getProductsSelector);
    const [category, setCategory] = useState([]);
    const [selected, setSelected] = useState({});
    const params = useParams();
    const [startPage, setStartPage] = useState(1);
    const perPage = 30;
    const [refreshOrders, setRefreshOrders] = useState(true);
    const [sort, setSort] = useState(1);
    const [products, setProducts] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [brands, setBrands] = useState([]);
    const ordersFilter = useMemo(
        () => ({
            perPage,
            startPage,
            sort,
            categories: params.categories,
            filter: { ...selected }
        }),
        [perPage, startPage, sort, params.categories, selected]
    );

    useEffect(() => {
        if (Object.keys(filterProducts).length > 0) {

            console.log(filterProducts)
            console.log("LLLLLL")
            console.log("LLLLLL")
            console.log("LLLLLL")
            console.log("LLLLLL")
            const brandsOpt = filterProducts.filters.brand.map((brandArray, index) => (
                <option key={brandArray[0]._id} value={brandArray[0]._id}>
                    {brandArray[0].name}
                </option>
            ));
            setBrands(brandsOpt);
        }
    }, [filterProducts]);


    useEffect(() => {
        if (getProducts) {
            if (Array.isArray(getProducts)) {
                setProducts(getProducts || []);
            } else {
                setProducts(getProducts.products || []);
                setTotalPages(getProducts.totalProductsCount || 0);
            }
        }
    }, [getProducts]);

    const sorting = useCallback(
        (event) => {
            const updatedSort = event === "new" ? 1 : event;
            const sortField = event === "new" ? "updatedAt" : "currentPrice";
            setSort(updatedSort); // Update `sort` state
            setRefreshOrders(true); // Trigger refresh
        },
        []
    );

    const filter = useCallback(
        (key, event) => {
            let updatedSelected;
            if (event === 0) {
                updatedSelected = { ...selected };
                delete updatedSelected[key];
            } else {
                updatedSelected = { ...selected, [key]: event };
            }

            setSelected(updatedSelected); // Update `selected` state
            setRefreshOrders(true); // Trigger refresh
        },
        [selected]
    );


    useEffect(() => {
        if (params.categories) {
            dispatch(filtersProductsOperation(ordersFilter));
        }
    }, [refreshOrders, params.categories, dispatch, ordersFilter]);

    useEffect(() => {
        if (params.categories) {
            dispatch(getProductsOperation(ordersFilter));
        }
    }, [refreshOrders, params.categories, dispatch, ordersFilter]);

    useEffect(() => {
        if (categoriesMenu.length > 0 && params?.categories) {
            const data = categoriesMenu.filter((item) => item.title === params.categories);
            setCategory(data[0]);
        }
    }, [categoriesMenu, params.categories]);

    useEffect(() => {
        if (localStorage.getItem("filtersProducts")) {
            const savedOrdersFilter = JSON.parse(localStorage.getItem("filtersProducts"));

            if (savedOrdersFilter.categories !== params.categories) {
                setSelected({}); // Reset selected filters
            } else {
                setSelected(savedOrdersFilter.filter || {}); // Restore filters
            }

            setStartPage(1); // Reset to the first page
        }
    }, [params.categories]);


    useEffect(() => {
        localStorage.setItem("filtersProducts", JSON.stringify(ordersFilter));
    }, [ordersFilter]);

    return (
        <>
            <section>
                <Crumbs category={category} />
                <div className={`d-flex justify-content-between mb-3 ${styles.filter__block}`}>
                    {/* <ProductsFilter
                        filterProducts={filterProducts}
                        filter={filter}
                        selected={selected}
                        ordersFilter={ordersFilter}
                    /> */}
                    <div className={styles.select__wrapper}>
                        <select
                            name="brands"
                            id="brands"
                            value={ordersFilter?.filter?.brand || ""}
                            onChange={(e) => {
                                filter("brand", e.target.value);
                            }}
                        >
                            <option value="">Бренд</option>
                            {brands}
                        </select>
                    </div>
                    <div>
                        <div className={styles.filter}>
                            <select
                                name="sort"
                                id="sort"
                                value={sort}
                                onChange={(e) => sorting(e.target.value)}
                            >
                                <option value={"new"}>Новинки</option>
                                <option value={-1}>За зменшенням ціни</option>
                                <option value={1}>За зростанням ціни</option>
                            </select>
                        </div>
                    </div>
                </div>

                <ProductsCards
                    className="cards__list"
                    products={products}
                    categoriesMenu={categoriesMenu}
                />
                <Pagination
                    perPage={perPage}
                    startPage={startPage}
                    setPage={setStartPage}
                    productsQuantity={totalPages}
                />
            </section>
        </>
    );
};

export default React.memo(Products);
