/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.scss';

const Pagination = ({
  perPage = 10,
  startPage = 1,
  productsQuantity = 0,
  setPage
}) => {
  const pageNumbers = [1];

  for (let i = 2; i <= Math.ceil(productsQuantity / perPage); i++) {
    pageNumbers.push(i);
  }

  if (startPage > pageNumbers.length) {
    setPage(1);
  }

  const handlePageChange = (page) => {
    if (page >= 1 && page <= pageNumbers.length) {
      console.log(page)
      setPage(page);
      window.scrollTo(0, 0);
    }
  };

  const pageNumbersList = pageNumbers.map((number, index) => {
    return (
      <li
        key={`${number}-${index}`}
        className={startPage === number ? `${styles.pageNumbersItem} ${styles.itemActive}` : styles.pageNumbersItem}
        onClick={() => handlePageChange(number)}
      >
        <span className={styles.pageNumbers}>{number}</span>
      </li>
    );
  });

  return (
    <nav className={styles.container}>
      <div
        data-testid='prev'
        className={startPage === 1 ? styles.arrow : `${styles.arrow} ${styles.arrowActive}`}
        onClick={() => handlePageChange(startPage - 1)} // Обработчик для предыдущей страницы
      >
        <span className={styles.prev}></span>
      </div>
      <ul className={styles.pagination}>
        {pageNumbersList}
      </ul>
      <div
        data-testid='next'
        className={startPage === pageNumbers.length ? styles.arrow : `${styles.arrow} ${styles.arrowActive}`}
        onClick={() => handlePageChange(startPage + 1)} // Обработчик для следующей страницы
      >
        <span className={styles.next}></span>
      </div>
    </nav>
  );
};

Pagination.propTypes = {
  perPage: PropTypes.number,
  startPage: PropTypes.number,
  setPage: PropTypes.func.isRequired,
  productsQuantity: PropTypes.number,
};

export default Pagination;
