import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MySelect from '../../../components/MyForms/MySelect/MySelect';
import MyTextInput from '../../../components/MyForms/MyTextInput/MyTextInput';
import { getPostCity, getPostRegion, getPostDepartment } from '../../../http/newPostAPI';
import { deliveryMethodAction } from '../../../store/cart/actions';
import { deliveryMethodSelector } from '../../../store/cart/selectors';
import MySelectWithSearch from '../../../components/MyForms/MySelectWithSearch/MySelectWithSearch';
import "./DeliveryDataInputs.scss";
import Select from 'react-select';
import  {  useRef } from 'react';

const DeliveryDataInputs = () => {
  const [regions, setRegions] = useState([]);
  const [isRegionSelected, setIsRegionSelected] = useState(false);
  const [cities, setCities] = useState([]);
  const [department, setDepartment] = useState([]);
  const dispatch = useDispatch();
  const deliveryMethod = useSelector(deliveryMethodSelector);

  useEffect(() => {
    getPostRegion().then(res => {
      setRegions(res.data);
    });
  }, []);

  const getCities = region => {
    setIsRegionSelected(true);

    if (region !== '') {
      const regionRef = regions.filter(r => {
        if (r.Description === region) {
          return r.Ref;
        }
        return null;
      })[0].Ref;

      getPostCity(regionRef).then(res => {
        setCities(res.data);
      });
    }
  };

  const getDepartment = city => {
    if (city !== '') {
      const matchingCity = cities.find(c => c.Description === city);
      if (matchingCity) {
        const citieRef = matchingCity.Ref;
  
        getPostDepartment(citieRef).then(res => {
          setDepartment(res.data);
        });
      }
    }
  };

  const regionOptions = regions.map(reg => {
    return (
      <option key={reg.Description} value={reg.Description}>
        {reg.Description}
      </option>
    );
  });

  const citiesOptions = cities.length ? (
    cities.map(city => {
      return (
        <option key={city.Description} value={city.Description}>
          {city.Description}
        </option>
      );
    })
  ) : (
    <option disabled>Дані по області не отримано</option>
  );

  const departmentOptions = department.map(dep => {
    return (
      <option key={dep.Description} value={dep.Description}>
        {dep.Description}
      </option>
    );
  });
  const AddressSelect = ({ options }) => {
    const handleChange = selectedOption => {
      console.log('Selected option:', selectedOption);
    };
  
    return (
      <Select
        options={options}
        onChange={handleChange}
        placeholder="Оберіть адресу"
      />
    );
  };
  return (
    <div>
      <MySelect
        label='Область'
        name='region'
        onClick={e => {
          getCities(e.target.value);
        }}
        tabIndex='0'
      >
        <option hidden value=''>
          Оберіть область доставки
        </option>
        {regionOptions}
      </MySelect>

      <MySelect
        disabled={!isRegionSelected}
        label='Місто'
        name='city'
        onClick={e => {
          getDepartment(e.target.value);
        }}
        tabIndex='0'
      >
        <option hidden value=''>
          Оберіть місто доставки
        </option>
        {citiesOptions}
      </MySelect>

      <MySelect
        label='Спосіб доставки'
        name='delivery'
        onClick={e => {
          dispatch(deliveryMethodAction(e.target.value));
        }}
        tabIndex='0'
      >
        <option hidden value=''>
          Оберіть спосіб доставки
        </option>
        {/* <option value='Самовивіз з магазину'>Самовивіз з магазину</option> */}
        <option value='Новою поштою'>Новою поштою</option>
        {/* <option value="Кур'єром по Києву">Кур'єром по Креме</option> */}
      </MySelect>
      {/* {deliveryMethod === 'Новою поштою' &&  */}
        <MySelect label='Оберіть адресу' name='address' tabIndex='0'>
          <option>Оберіть адресу</option>
          {departmentOptions}
        </MySelect>
        {/* // <div> 
          //  <MyTextInput list="brow" label="Оберіть адресу" name='address' type='text' placeholder="Оберіть адресу" tabIndex='0' />
          // <datalist id="brow">
          //   {departmentOptions}
          // </datalist>  
          //  <MySelectWithSearch options={department} placeholder="Оберіть адресу" />
        // </div>  */}

      {/* } */}
      {deliveryMethod === "Кур'єром по Києву" && (
        <MyTextInput label='Адреса' name='address' type='text' placeholder='Введіть адресу доставки' tabIndex='-1' />
      )}
    </div>
  );
};

export default DeliveryDataInputs;
