import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import { getLocalCartSelector } from "../../store/cart/selectors";
import { saveModalOrderAction } from "../../store/modal/actions";
import {
  cartTotalPriceAction,
  saveLocalCartAction,
} from "../../store/cart/actions";
import { calculateTotalPrice } from "../../utils/func";
import CartIcon from "./CartIcon/CartIcon";
import { getBottleCountAndPrice } from "../../utils/bottlePrice";
import "./AddToCartBtn.scss";

const AddToCartButton = ({ admin, product, id, currentPrice, ml }) => {
  const [isCart, setIsCart] = useState(false); // Local state to check if item is in cart
  const [isAnimating, setIsAnimating] = useState(false); // Local state for animation
  const [bottleCost, setBottleCost] = useState(null);
  const dispatch = useDispatch();
  const localCart = useSelector(getLocalCartSelector);

  // Check if product is in cart
  const isProductInCart = (cart, productId) => {
    // TODO если cart это объект, добавить его как массив
    return (
      Array.isArray(cart) && cart.some((item) => item.product._id === productId)
    );
  };
  const calculatedBottleCost = useMemo(() => {
    return product.bottling && ml ? getBottleCountAndPrice(ml) : 0;
  }, [ml, product.bottling]);

  const totalPrice = useMemo(() => {
    return currentPrice;
  }, [currentPrice]);

  useEffect(() => {
    setBottleCost(calculatedBottleCost);
  }, [currentPrice, calculatedBottleCost]);

  // Check if product is in cart on component mount
  useEffect(() => {
    setIsCart(isProductInCart(localCart, product._id));
  }, [localCart, product._id]);

  const openCart = () => {
    dispatch(saveModalOrderAction(true));
  };

  const addToCart = () => {
    let productCart = [...localCart];
    const existingProductIndex = productCart.findIndex(
      (item) => item.product._id === product._id
    );

    if (existingProductIndex !== -1) {
      // Increase quantity if the product is already in the cart
      productCart[existingProductIndex].cartQuantity = product.bottling
        ? ml || 1
        : 1;
      productCart[existingProductIndex].bottleCost = bottleCost; // Update total price with bottle cost if applicable
    } else {
      // Add new product to the cart

      let newProduct = {
        bottling: product.bottling,
        cartQuantity: product.bottling ? ml || 1 : 1,
        product: product,
        currentPrice: totalPrice, // Store total price with bottle cost if applicable
        bottleCost: bottleCost,
      };
      productCart.push(newProduct);
      newProduct = null;
    }

    // Save cart to localStorage
    localStorage.setItem("cart", JSON.stringify(productCart));

    // Update total price in the cart
    const updatedTotalPrice = calculateTotalPrice(productCart, false);
    dispatch(cartTotalPriceAction(updatedTotalPrice));
    dispatch(saveLocalCartAction(productCart));

    setIsCart(true);
    setIsAnimating(true); // Start animation

    // Stop animation after 1.5 seconds
    setTimeout(() => setIsAnimating(false), 1500);
  };

  return (
    <>
      <div>
        {isCart ? (
          <Button
            onClick={openCart}
            className="cursor-pointer"
            variant="dark"
            disabled={
              product.bottling
                ? product.quantityMl === 0
                : product.quantity === 0
            }
          >
            <CartIcon isAnimating={isAnimating} isCart={isCart} uniqueId={id} />
          </Button>
        ) : (
          <Button
            onClick={addToCart}
            className="cursor-pointer"
            variant="dark"
            disabled={
              product.bottling
                ? product.quantityMl === 0
                : product.quantity === 0
            }
          >
            <CartIcon isAnimating={isAnimating} isCart={isCart} uniqueId={id} />
          </Button>
        )}
      </div>
    </>
  );
};

export default AddToCartButton;
