import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { NavLink, useParams } from "react-router-dom";
import Container from "../../../components/Container/Container";
import styles from "./Product.module.scss";
import AddToCartButton from "../../../components/AddToCartButton/AddToCartButton";
import PriceBlock from "../../../components/PriceBlock/PriceBlock";
import { useSelector, useDispatch } from "react-redux";
import { getOneProductSelector } from "../../../store/products/selectors";
import { getOneProductOperation } from "../../../store/products/operations";
import Loader from "../../../components/Loader/Loader";
import ProductSizes from "../../../components/ProductSizes/ProductSizes";
import ProductDetails from "./ProductDetails/ProductDetails";
import defaultImg from '../../../theme/assets/images/pexels-rethaferguson-3059609.jpg';
import Crumbs from "../../../components/Crumbs/Crumbs";

const Product = () => {
    const dispatch = useDispatch();
    const auth = useContext(AuthContext);
    const { id } = useParams();
    const product = useSelector(getOneProductSelector);
    const [mlOptions, setMlOptions] = useState([]);
    const [ml, setMl] = useState(1);
    const [characteristics, setCharacteristics] = useState([]);
    const [isInCart, setIsInCart] = useState(false); // Local state for cart check
    const detailsRef = useRef(null);

    const choiceMl = (ml) => {
        setMl(ml);
        if (detailsRef.current) {
            detailsRef.current.open = false;
        }
    };

    useEffect(() => {
        dispatch(getOneProductOperation(id));
    }, [dispatch, id]);

    useEffect(() => {
        if (product.bottling) {
            const options = [];
            for (let i = 1; i <= product.quantityMl; i++) {
                options.push(
                    <li key={i} value={i} onClick={() => choiceMl(i)}>
                        {i} мл
                    </li>
                );
            }
            setMlOptions(options);
        }
        characters();
    }, [product.quantityMl, product.brand, product.manufacturerCountry, product.bottling, product.sizes]);

    const characters = () => {
        const arr = [
            { "Категорія": product?.categories?.name },
            { "Бренд": product?.brand?.name },
            { "Країна виробник": product.manufacturerCountry },
            product.bottling ? { "Розпив": "Так" } : { "Емність": product.sizes }
        ];
        setCharacteristics(arr);
    };

    // Check if the product is in the cart from local storage
    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem("cart") || "[]");
        setIsInCart(cart.some(item => item.id === product._id));
    }, [product._id]);

    if (Object.keys(product).length === 0) {
        return <Loader />;
    }

    return (
        <>
            <Container>
                <Crumbs product={product} />
                <div className={styles.product__container}>
                    <div className={styles.product__wrapper}>
                        <div className={styles.product__imageWrapper}>
                            <img
                                className={styles.product__image}
                                src={product.imageFirst ? product.imageFirst : defaultImg}
                                alt="Product image"
                            />
                        </div>
                    </div>
                    <div className={styles.product__wrapper}>
                        {!!product.discount && product.discount > 0 && (
                            <p className={styles.product__itemEnable}>
                                <span className={styles.product__disactive}>
                                    Знижка {product.discount}% від ціни
                                </span>
                            </p>
                        )}
                        <h2 className={styles.product__title}>{product.name}</h2>
                        <div className={`${styles.product__block} mb-3`}>
                            {product.productsGroups?.products.length > 0 && (
                                <ProductSizes id={product._id} products={product.productsGroups.products} />
                            )}
                            <div className={styles.product__blockPrice}>
                                <PriceBlock previousPrice={product.previousPrice} currentPrice={product.currentPrice} />
                            </div>
                            <p className={styles.product__itemEnable}>
                                {product.quantity > 0 ? (
                                    <span className={styles.product__active}>Є в наявностi</span>
                                ) : (
                                    <span className={styles.product__disactive}>Немає в наявностi</span>
                                )}
                            </p>
                            {product.bottling && (
                                <>
                                    <AddToCartButton
                                        admin={auth.permissions?.admin?.access || false}
                                        product={product}
                                        id={product._id}
                                        orderButton={false}
                                        currentPrice={product.currentPrice}
                                        ml={ml}
                                    />
                                    {!isInCart ? (
                                        <div className={styles.group__container}>
                                            <label htmlFor="ml-options">Виберіть кількість мілілітрів:</label>
                                            <div className={styles.group__wrapperSelect}>
                                                <p>{ml ? `${ml} мл` : "Виберіть к-ть"}</p>
                                                <details ref={detailsRef}>
                                                    <summary className={styles.group__arrow}></summary>
                                                    <ul className={styles.group__select} id="ml-options">
                                                        {mlOptions}
                                                    </ul>
                                                </details>
                                            </div>
                                        </div>
                                    ) : (
                                        <p className={styles.product__note}>Кількість не можна змінювати, оскільки товар вже в корзині</p>
                                    )}
                                </>
                            )}
                            {!product.bottling && (
                                <AddToCartButton
                                    admin={auth.permissions?.admin?.access || false}
                                    product={product}
                                    id={product._id}
                                    orderButton={false}
                                    currentPrice={product.currentPrice}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <ProductDetails description={product.description} characteristics={characteristics} />
            </Container>
        </>
    );
};

export default Product;
